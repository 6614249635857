import React from "react";

// Components
import { css } from "@emotion/core";
import Layout from "../components/Layouts/Layout";
import SEO from "../components/Utilities/SEO";
import { colors, fonts, mediaQueries } from "../configs/_configSite";
import ImgBackgroundLogoWallpaper from "../images/backgrounds/anana-vestige-logo-wallpaper-background-white.png";

export default () => (
  <Layout>
    <SEO title="Privacy Policy | Anana Vestige" description="" />
    <section
      css={css`
      padding: 120px 30px 30px 30px;
      background-image: url('${ImgBackgroundLogoWallpaper}');

        h2 {
          color: ${colors.darkGray};
          font-family: ${fonts.primary};
          font-weight: 100;
          font-size: 2.5rem;
          margin-bottom: 30px;
          @media (max-width: ${mediaQueries.xxs}px) {
            margin-bottom: 15px;
            font-size: 2rem;
          }
        }
        a {
          color: ${colors.gold}
        }
        p, li, address, span {
          color: ${colors.darkGray};
          font-family: ${fonts.tertiary};
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 1.5;
          text-align: justify;
          margin-bottom: 30px;
        }
      `}
    >
      <h1>Welcome to our Privacy Policy</h1>
      <h3>Your privacy is critically important to us.</h3>
      <br />
      <p>
        Anana Vestige is located at:
        <address>
          Anana Vestige
          <br />
          14 Paris Hill Dr. San Diego
          <br />
          92117 - California , United States of America
          <br />
          9512903033
        </address>
      </p>
      <p>
        <>
          It is Anana Vestige&apos;s policy to respect your privacy regarding
          any information we may collect while operating our website. This
          Privacy Policy applies to&nbsp;
        </>
        <a href="https://ananavestige.com">https://ananavestige.com </a>
        <>
          (hereinafter, &quot;us&quot;, &quot;we&quot;, or
          &quot;https://ananavestige.com&quot;). We respect your privacy and are
          committed to protecting personally identifiable information you may
          provide us through the Website. We have adopted this privacy policy
          (&quot;Privacy Policy&quot;) to explain what information may be
          collected on our Website, how we use this information, and under what
          circumstances we may disclose the information to third parties. This
          Privacy Policy applies only to information we collect through the
          Website and does not apply to our collection of information from other
          sources.
        </>
      </p>
      <p>
        This Privacy Policy, together with the Terms and conditions posted on
        our Website, set forth the general rules and policies governing your use
        of our Website. Depending on your activities when visiting our Website,
        you may be required to agree to additional terms and conditions.
      </p>
      <h2>Website Visitors</h2>
      <p>
        Like most website operators, Anana Vestige collects
        non-personally-identifying information of the sort that web browsers and
        servers typically make available, such as the browser type, language
        preference, referring site, and the date and time of each visitor
        request. Anana Vestige&apos;s purpose in collecting non-personally
        identifying information is to better understand how Anana Vestige&apos;s
        visitors use its website. From time to time, Anana Vestige may release
        non-personally-identifying information in the aggregate, e.g., by
        publishing a report on trends in the usage of its website.
      </p>
      <p>
        Anana Vestige also collects potentially personally-identifying
        information like Internet Protocol (IP) addresses for logged in users
        and for users leaving comments on https://ananavestige.com blog posts.
        Anana Vestige only discloses logged in user and commenter IP addresses
        under the same circumstances that it uses and discloses
        personally-identifying information as described below.
      </p>
      <h2>Gathering of Personally-Identifying Information</h2>
      <p>
        Certain visitors to Anana Vestige&apos;s websites choose to interact
        with Anana Vestige in ways that require Anana Vestige to gather
        personally-identifying information. The amount and type of information
        that Anana Vestige gathers depends on the nature of the interaction. For
        example, we ask visitors who sign up for a blog at
        https://ananavestige.com to provide a username and email address.
      </p>
      <h2>Security</h2>
      <p>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </p>
      <h2>Advertisements</h2>
      <p>
        Ads appearing on our website may be delivered to users by advertising
        partners, who may set cookies. These cookies allow the ad server to
        recognize your computer each time they send you an online advertisement
        to compile information about you or others who use your computer. This
        information allows ad networks to, among other things, deliver targeted
        advertisements that they believe will be of most interest to you. This
        Privacy Policy covers the use of cookies by Anana Vestige and does not
        cover the use of cookies by any advertisers.
      </p>
      <h2>Links To External Sites</h2>
      <p>
        Our Service may contain links to external sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party&apos;s site. We strongly advise you to review the Privacy
        Policy and terms and conditions of every site you visit.
      </p>
      <p>
        We have no control over, and assume no responsibility for the content,
        privacy policies or practices of any third party sites, products or
        services.
      </p>
      <h2>Aggregated Statistics</h2>
      <p>
        Anana Vestige may collect statistics about the behavior of visitors to
        its website. Anana Vestige may display this information publicly or
        provide it to others. However, Anana Vestige does not disclose your
        personally-identifying information.
      </p>
      <h2>Affiliate Disclosure</h2>
      <p>
        This site uses affiliate links and does earn a commission from certain
        links. This does not affect your purchases or the price you may pay.
      </p>
      <h2>Cookies</h2>
      <p>
        To enrich and perfect your online experience, Anana Vestige uses
        &quot;Cookies&quot;, similar technologies and services provided by
        others to display personalized content, appropriate advertising and
        store your preferences on your computer.
      </p>
      <p>
        A cookie is a string of information that a website stores on a
        visitor&apos;s computer, and that the visitor&apos;s browser provides to
        the website each time the visitor returns. Anana Vestige uses cookies to
        help Anana Vestige identify and track visitors, their usage of
        https://ananavestige.com, and their website access preferences. Anana
        Vestige visitors who do not wish to have cookies placed on their
        computers should set their browsers to refuse cookies before using Anana
        Vestige&apos;s websites, with the drawback that certain features of
        Anana Vestige&apos;s websites may not function properly without the aid
        of cookies.
      </p>
      <p>
        By continuing to navigate our website without changing your cookie
        settings, you hereby acknowledge and agree to Anana Vestige&apos;s use
        of cookies.
      </p>
      <h2>E-commerce</h2>
      <p>
        Those who engage in transactions with Anana Vestige – by purchasing
        Anana Vestige&apos;s services or products, are asked to provide
        additional information, including as necessary the personal and
        financial information required to process those transactions. In each
        case, Anana Vestige collects such information only insofar as is
        necessary or appropriate to fulfill the purpose of the visitor&apos;s
        interaction with Anana Vestige. Anana Vestige does not disclose
        personally-identifying information other than as described below. And
        visitors can always refuse to supply personally-identifying information,
        with the caveat that it may prevent them from engaging in certain
        website-related activities.
      </p>
      <h2>Privacy Policy Changes</h2>
      <p>
        Although most changes are likely to be minor, Anana Vestige may change
        its Privacy Policy from time to time, and in Anana Vestige&apos;s sole
        discretion. Anana Vestige encourages visitors to frequently check this
        page for any changes to its Privacy Policy. Your continued use of this
        site after any change in this Privacy Policy will constitute your
        acceptance of such change.
      </p>
      <p />
      <h2>Contact Information</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        via&nbsp;
        <a href="mailto:hello@ananavestige.com">email</a>
        &nbsp;
        <a href="tel:9512903033">phone</a>
      </p>
    </section>
  </Layout>
);
